/* eslint-disable no-use-before-define */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
    Box,
    Drawer,
    Hidden,
    List,
    Button,
    Grid,
    ListSubheader,
    Typography,
    Snackbar,
    Alert,
    LinearProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from '../../../hooks/useAuth';
import NavItem from './NavItem';
import { THEMES } from '../../../constants';
import DarkLogo from '../../../cloud-ops-logo_dark_2x.png';
import LightLogo from '../../../cloud-ops-logo_light_2x.png';
import AnimatedUserAvatar from '../../../components/Avatars/AnimatedUserAvatar';
import {
    DashboardRounded,
    PeopleRounded,
    PeopleOutlined,
    Receipt,
    Construction,
    Cloud,
    TimelineRounded,
    BuildRounded,
    TipsAndUpdatesRounded,
    SearchOutlined,
} from '@mui/icons-material';
import PermissionsGateContext from '../../../contexts/PermissionsGateContext';
import {
    ADD_ON_SW_ROLES,
    PERMISSIONS,
    SCOPES,
    SCOPES_SW,
} from '../../../permissionMaps';
import { API, graphqlOperation } from 'aws-amplify';
import { onChannelMessage } from '../../../graphql/subscriptions';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';

const sections = [
    {
        subheader: 'APPLICATIONS',
        //scopes: [SCOPES.manageCustomer],
        items: [
            {
                title: 'Customers',
                icon: PeopleOutlined,
                href: '/customers',
                scopes: PERMISSIONS[SCOPES.manageCustomer],
                items: [
                    {
                        title: 'Customer List',
                        href: '/customers/customersList/view',
                        scopes: PERMISSIONS[SCOPES.manageCustomer],
                    },
                    {
                        title: 'Add Customer',
                        href: '/customers/addcustomer',
                        scopes: [SCOPES.createCustomer, SCOPES.manageCustomer],
                    },
                ],
            },
            {
                title: 'Video Manager EX',
                icon: Construction,
                href: '/videomanager',
                scopes: PERMISSIONS[SCOPES.manageVideoManager],
                items: [
                    {
                        title: 'Overview',
                        href: '/videomanager/overview',
                        scopes: [
                            SCOPES.manageVideoManager,
                            SCOPES.viewVideoManager,
                        ],
                    },
                    {
                        title: 'Upload Files',
                        href: '/videomanager/upload',
                        scopes: [
                            SCOPES.manageVideoManager,
                            SCOPES.uploadPackageVideoManager,
                            SCOPES.upgradeInternalVideoManager,
                            SCOPES.upgradeVideoManager,
                        ],
                    },
                ],
            },
            {
                title: 'Licences',
                icon: Receipt,
                href: '/licence',
                scopes: [
                    SCOPES.viewLicense,
                    SCOPES.createLicense,
                    SCOPES.manageLicense,
                    SCOPES.manageSpreadsheet,
                ],
                items: [
                    {
                        title: 'List',
                        href: '/licence/list',
                        scopes: [
                            SCOPES.viewLicense,
                            SCOPES.createLicense,
                            SCOPES.manageLicense,
                        ],
                    },
                    {
                        title: 'Create Licence',
                        href: '/licence/create',
                        scopes: [SCOPES.createLicense, SCOPES.manageLicense],
                    },
                    {
                        title: 'Product Codes',
                        href: '/licence/productcodes',
                        scopes: [SCOPES.manageSpreadsheet],
                    },
                    {
                        title: 'Metrics',
                        href: '/licence/metrics',
                        scopes: [SCOPES.manageLicense],
                    },
                    // {
                    //     title: 'License Report',
                    //     href: '/governance/incidentsOverview',
                    // },
                ],
            },
            {
                title: 'VMEX AI Chat',
                icon: TipsAndUpdatesRounded,
                href: '/gpt',
                scopes: [
                    SCOPES.adminAll,
                    SCOPES.manageGenAi,

                    SCOPES.queryGenericModelsAi,
                    SCOPES.queryVMEXModelAi,
                ],
                items: [
                    {
                        title: 'VMEX AI',
                        href: '/gpt',
                        scopes: [
                            SCOPES.adminAll,
                            SCOPES.manageGenAi,
                            SCOPES.queryGenericModelsAi,
                            SCOPES.queryVMEXModelAi,
                        ],
                    },
                    {
                        title: 'Queries List',
                        href: '/gpt/queriesList',
                        scopes: [SCOPES.adminAll, SCOPES.manageGenAi],
                    },
                ],
            },
            {
                title: 'Cloud Environment',
                icon: Cloud,
                href: '/cloudenvironment',
                scopes: PERMISSIONS[
                    (SCOPES.manageEnvironment, SCOPES.createEnvironment)
                ],
                items: [
                    {
                        title: 'Create Environment',
                        href: '/cloudenvironment/add',
                        scopes: [
                            SCOPES.manageEnvironment,
                            SCOPES.createEnvironment,
                        ],
                    },
                    // {
                    //     title: 'License Report',
                    //     href: '/governance/incidentsOverview',
                    // },
                ],
            },
        ],
    },
    {
        subheader: 'MONITORING',
        //scopes: [SCOPES.dashboardCanView],
        items: [
            {
                title: 'Dashboards',
                icon: DashboardRounded,
                href: '/dashboards/serviceHealth',
                //scopes: [SCOPES.dashboardCanView],
                items: [
                    {
                        title: 'Service Health',
                        href: '/dashboards/serviceHealth',
                    },
                ],
            },
            {
                title: 'Activities',
                icon: TimelineRounded,
                href: '/activities/view',
                scopes: [SCOPES.adminAll, ...PERMISSIONS[SCOPES.viewActivity]],
                items: [
                    {
                        title: 'Users Activities',
                        href: '/activities/view',
                    },
                ],
            },
        ],
    },
];

const sections_admin = [
    {
        subheader: 'ADMIN',
        scopes: [SCOPES.adminAll, ...PERMISSIONS[SCOPES.manageUsers]],
        items: [
            {
                title: 'Users and Roles',
                icon: PeopleRounded,
                items: [
                    {
                        title: 'Overview',
                        scopes: PERMISSIONS[SCOPES.manageUsers],
                        href: '/admin/usersAndRoles',
                    },
                    {
                        title: 'View Users',
                        scopes: PERMISSIONS[SCOPES.manageUsers],
                        href: '/admin/usersAndRoles/view',
                    },
                ],
            },
        ],
    },
];

const sections_sw = [
    {
        subheader: 'SUPPORT WEB',
        items: [
            {
                title: 'RMA',
                icon: BuildRounded,
                href: '/rma',
                scopes: [SCOPES_SW.viewRMAReport],
                items: [
                    {
                        title: 'Create RMA',
                        href: '/rma',
                    },
                    {
                        title: 'RMA Report',
                        href: '/rma/report',
                    },
                ],
            },
            {
                title: 'SEARCH',
                icon: SearchOutlined,
                href: '/search',
                scopes: [SCOPES_SW.viewRMAReport],
                items: [
                    {
                        title: 'Search',
                        href: '/search',
                    },
                ],
            },
        ],
    },
];

const sections_portal_rma = [
    {
        subheader: 'RMA Portal',
        items: [
            {
                title: 'RMA Portal',
                icon: BuildRounded,
                href: '/rma_portal',
                scopes: [SCOPES.adminAll, SCOPES.managerRMA],
                items: [
                    {
                        title: 'Manage Users',
                        href: '/rma_portal/userList',
                    },
                ],
            },
        ],
    },
];

function renderNavItems({
    key,
    items,
    pathname,
    isExpandAll,
    depth = 0,
    sectionScopes,
}) {
    return (
        <List key={_.random(9999, 999999) + '_list'} disablePadding>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({
                        acc,
                        item,
                        pathname,
                        isExpandAll,
                        depth,
                        sectionScopes,
                    }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({
    acc,
    pathname,
    isExpandAll,
    item,
    depth,
    sectionScopes,
}) {
    // const key = item.title + depth;

    if (item.items) {
        // const open = matchPath(pathname, {
        //   path: item.href,
        //   exact: false,
        // });

        //const open = isExpandAll

        /*
    Item contains all of the top-level Nav bar items. We need to check if 
    title === Service Register as we will not render this nav item unless
    the currently authenticated user is part of groups === 'Managers'
    */

        acc.push(
            <PermissionsGateContext
                hideComponent={true}
                scopes={item.scopes || sectionScopes}
                key={_.random(9999, 999999) + '_permissions'}
            >
                <NavItem
                    depth={depth}
                    icon={item.icon}
                    info={item.info}
                    key={_.random(9999, 999999) + '_item'}
                    open={isExpandAll}
                    title={item.title}
                    style={{ display: 'block' }}
                >
                    {renderNavItems({
                        depth: depth + 1,
                        pathname,
                        items: item.items,
                        isExpandAll: isExpandAll,
                    })}
                </NavItem>
            </PermissionsGateContext>
        );
    } else {
        acc.push(
            <PermissionsGateContext
                hideComponent={true}
                scopes={item.scopes || sectionScopes}
                key={_.random(9999, 999999) + '_permissions'}
            >
                <NavItem
                    depth={depth}
                    href={item.href}
                    icon={item.icon}
                    info={item.info}
                    key={_.random(9999, 999999) + '_navitem'}
                    title={item.title}
                    open={isExpandAll}
                />
            </PermissionsGateContext>
        );
    }

    return acc;
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 290,
        height: 'calc(100% - 41px)',
        boxShadow: '0 1px 4px rgba(0,0,2,0.2) !important',
    },
    desktopDrawer: {
        width: 290,
        height: 'calc(100% - 41px)',
        border: 'none !important',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        boxShadow: '0 1px 4px rgba(0,0,2,0.2) !important',
    },
    username: {
        margin: 'auto',
        width: '100%',
        padding: '0px 10px 0px 10px',
    },
    bottomPush: {
        position: 'fixed',
        bottom: 0,
        textAlign: 'center',
        paddingBottom: 5,
        backgroundColor: theme.palette.background.paper,
        width: 290,
    },
}));

const NavBar = ({ onMobileClose, openMobile, onNavClose, openNav }) => {
    const classes = useStyles();
    const location = useLocation();
    const { user } = useAuth();
    const [isExpandAll, setExpandAll] = useState(false);
    const [alertColor, setAlertColor] = useState({});
    const snackBarRef = useRef([]);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState([]);
    const vertical = 'bottom';
    const horizontal = 'right';
    let subscribeMessage;

    async function subscribe_personal_msg() {
        try {
            subscribeMessage = await API.graphql(
                graphqlOperation(onChannelMessage, {
                    userId: user.cognito_username,
                })
            ).subscribe({
                next: (msg) => {
                    const requestId = msg.value.data.onChannelMessage.requestId;
                    const type = msg.value.data.onChannelMessage.type;
                    const progressPercent = parseFloat(
                        msg.value.data.onChannelMessage?.ProgressPercent
                    );
                    const diff = Math.random() * 10;
                    const diff2 = Math.random() * 10;

                    const newMessage = {
                        message: msg.value.data.onChannelMessage.message,
                        type: type,
                        requestId: requestId,
                        ProgressPercent: progressPercent,
                        Buffer: progressPercent
                            ? progressPercent + diff + diff2
                            : 0,
                    };

                    setOpen((prev) => ({
                        ...prev,
                        [requestId]: true,
                    }));

                    switch (type) {
                        case 'success':
                            setAlertColor((prev) => ({
                                ...prev,
                                [requestId]: 'success',
                            }));
                            break;
                        case 'progress':
                            setAlertColor((prev) => ({
                                ...prev,
                                [requestId]: 'info',
                            }));
                            break;
                        default:
                            break;
                    }

                    setMessage((prev) => {
                        const index = _.findIndex(prev, {
                            requestId: requestId,
                        });
                        if (index !== -1) {
                            return _.map(prev, (msg, k) => {
                                if (k === index) {
                                    return newMessage;
                                } else {
                                    return msg;
                                }
                            });
                        } else {
                            return [...prev, newMessage];
                        }
                    });
                },
                error: (error) => console.log('Subscription error', error),
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    async function subscribe_group_msg() {
        try {
            let role = JSON.parse(user?.user?.roles);
            if (!role) return;
            subscribeMessage = await API.graphql(
                graphqlOperation(onChannelMessage, {
                    userId: role['role'],
                })
            ).subscribe({
                next: (msg) => {
                    setMessage({
                        message: msg.value.data.onChannelMessage.message,
                        type: msg.value.data.onChannelMessage.type,
                    });
                    setOpen(true);
                },
                error: (error) => console.log('Subscription error', error),
            });
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        subscribe_personal_msg();
        subscribe_group_msg();
        // sendMsg('test', user.cognito_username);
        //setActivity('main_route_2', user.user);
        return () => {
            subscribeMessage.unsubscribe();
        };
    }, []);

    // async function sendMsg() {
    //     try {
    //         await API.graphql(
    //             graphqlOperation(createMessage, {
    //                 input: {
    //                     message: 'Send Test MSG',
    //                     userId: user.cognito_username,
    //                 },
    //             })
    //         );
    //     } catch (error) {
    //         console.log('error', error);
    //     }
    // }

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        if (openNav && onNavClose) {
            onNavClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const getNavItems = (open) => {
        return (
            <React.Fragment>
                {sections.map((section, k) => (
                    <PermissionsGateContext
                        key={_.random(9999, 999999) + '_permissions'}
                        hideComponent={true}
                        scopes={section.scopes || []}
                    >
                        <List
                            key={_.random(9999, 999999) + '_subheader'}
                            subheader={
                                <ListSubheader disableGutters disableSticky>
                                    {section.subheader}
                                </ListSubheader>
                            }
                        >
                            {renderNavItems({
                                key: k,
                                items: section.items,
                                pathname: location.pathname,
                                isExpandAll: open,
                                sectionScopes: section.scopes,
                            })}
                        </List>
                    </PermissionsGateContext>
                ))}
                <PermissionsGateContext
                    hideComponent={true}
                    scopes={[ADD_ON_SW_ROLES.viewRMAReport]}
                >
                    {sections_sw.map((section, k) => (
                        <PermissionsGateContext
                            hideComponent={true}
                            scopes={section.scopes || []}
                            key={_.random(9999, 999999) + '_permissions'}
                        >
                            <List
                                key={_.random(9999, 999999) + '_subheader'}
                                subheader={
                                    <ListSubheader disableGutters disableSticky>
                                        {section.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItems({
                                    key: k,
                                    items: section.items,
                                    pathname: location.pathname,
                                    isExpandAll: open,
                                    sectionScopes: section.scopes,
                                })}
                            </List>
                        </PermissionsGateContext>
                    ))}
                </PermissionsGateContext>
                <PermissionsGateContext
                    hideComponent={true}
                    scopes={[SCOPES.adminAll]}
                >
                    {sections_portal_rma.map((section, k) => (
                        <PermissionsGateContext
                            hideComponent={true}
                            scopes={section.scopes || []}
                            key={_.random(9999, 999999) + '_permissions'}
                        >
                            <List
                                key={_.random(9999, 999999) + '_subheader'}
                                subheader={
                                    <ListSubheader disableGutters disableSticky>
                                        {section.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItems({
                                    key: k,
                                    items: section.items,
                                    pathname: location.pathname,
                                    isExpandAll: open,
                                    sectionScopes: section.scopes,
                                })}
                            </List>
                        </PermissionsGateContext>
                    ))}
                </PermissionsGateContext>
                <PermissionsGateContext
                    hideComponent={true}
                    scopes={[SCOPES.manageUsers]}
                >
                    {sections_admin.map((section, k) => (
                        <PermissionsGateContext
                            hideComponent={true}
                            scopes={section.scopes || []}
                            key={_.random(9999, 999999) + '_permissions'}
                        >
                            <List
                                key={_.random(9999, 999999) + '_subheader'}
                                subheader={
                                    <ListSubheader disableGutters disableSticky>
                                        {section.subheader}
                                    </ListSubheader>
                                }
                            >
                                {renderNavItems({
                                    key: k,
                                    items: section.items,
                                    pathname: location.pathname,
                                    isExpandAll: open,
                                    sectionScopes: section.scopes,
                                })}
                            </List>
                        </PermissionsGateContext>
                    ))}
                </PermissionsGateContext>
            </React.Fragment>
        );
    };

    const [navItems, setNavItems] = useState(getNavItems(isExpandAll));

    useEffect(() => {
        setNavItems(getNavItems(isExpandAll));
    }, [isExpandAll]);

    const content = (
        <React.Fragment>
            <Grid container style={{ marginBottom: '25px' }}>
                <Grid item xs={12} p={1}>
                    <RouterLink to="/home">
                        <Box p={3} display="flex" justifyContent="center">
                            <img
                                src={
                                    useTheme().name === THEMES.LIGHT
                                        ? LightLogo
                                        : DarkLogo
                                }
                                alt="Video Manager EX CloudOps Portal"
                                width="110px"
                            />
                        </Box>
                    </RouterLink>
                    <Box p={1} display="flex">
                        <Box display="flex" justifyContent="left">
                            <AnimatedUserAvatar
                                component={RouterLink}
                                to="/profile"
                            />
                        </Box>
                        <Box className={classes.username}>
                            <Typography variant="h4" color="textSecondary">
                                {user.user.name}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} p={1}>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <Box p={2}>{navItems}</Box>
                    </PerfectScrollbar>
                </Grid>
            </Grid>
        </React.Fragment>
    );

    const handleClose = (event, reason, requestId) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen((prev) => ({
            ...prev,
            [requestId]: false,
        }));
        snackBarRef.current = [];
        setMessage((prev) => {
            return _.filter(prev, (msg) => msg.requestId !== requestId);
        });
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const loadMessages = () => {
        return _.map(message, (msg, k) => {
            if (open[msg.requestId]) {
                let margin = 0;
                for (let index = 0; index < k; index++) {
                    margin += snackBarRef.current[index].offsetHeight + 10;
                }
                return (
                    <Snackbar
                        key={msg.requestId}
                        style={{
                            borderRadius: '5px',
                            marginBottom: `${margin}px`,
                        }}
                        ref={(el) => (snackBarRef.current[k] = el)}
                        anchorOrigin={{ vertical, horizontal }}
                        open={open[msg.requestId]}
                        onClose={(event, reason) =>
                            handleClose(event, reason, msg.requestId)
                        }
                    >
                        <Alert
                            onClose={(event, reason) =>
                                handleClose(event, reason, msg.requestId)
                            }
                            sx={{
                                minWidth: '290px',
                                borderRadius: '5px',
                                minHeight: '58px',
                            }}
                            severity={alertColor[msg.requestId]}
                        >
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            minWidth: '290px',
                                            mr: 1,
                                        }}
                                    >
                                        <LinearProgress
                                            sx={{ minWidth: '290px' }}
                                            variant="buffer"
                                            color={alertColor[msg.requestId]}
                                            value={parseFloat(
                                                msg.ProgressPercent
                                            )}
                                            valueBuffer={msg.Buffer}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography
                                            variant="body2"
                                            color="black"
                                        >{`${Math.round(
                                            msg.ProgressPercent
                                        )}%`}</Typography>
                                    </Box>
                                </Box>

                                {`[${msg.requestId}] - ${msg.message}`}
                            </>
                        </Alert>
                    </Snackbar>
                );
            }
        });
    };

    return (
        <>
            {loadMessages()};
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                >
                    {content}
                    <Grid item xs={12} p={2} className={classes.bottomPush}>
                        <Button onClick={() => setExpandAll(!isExpandAll)}>
                            <span>
                                {isExpandAll ? 'Collapse All' : 'Expand All'}
                            </span>
                        </Button>
                    </Grid>
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open={openNav}
                    variant="persistent"
                >
                    {content}
                    <Grid item xs={12} p={2} className={classes.bottomPush}>
                        <Button
                            // className={classes.button}
                            // activeClassName={classes.active}
                            onClick={() => setExpandAll(!isExpandAll)}
                            // style={style}
                        >
                            {/* {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )} */}
                            <span>
                                {isExpandAll ? 'Collapse All' : 'Expand All'}
                            </span>
                            {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
                        </Button>
                    </Grid>
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
    onNavClose: PropTypes.func,
    openNav: PropTypes.bool,
};

export default NavBar;
